'use client';

import { useState, useEffect, useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';
import { Onest } from 'next/font/google';
import Image from 'next/image';
import { Lens } from './ui/lens';

const headFont = Onest({
    weight: ['400', '500', '600'],
    subsets: ['latin'],
});

export default function FeaturesTabs() {
    const [activeTab, setActiveTab] = useState('pixel-perfect');
    const [isHovered, setIsHovered] = useState(false);
    const [hovering, setHovering] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const tabsDisplay: any = {
        'pixel-perfect': 'Pixel-Perfect',
        'bg-preservation': 'Background Preservation',
        'versatile-poses': 'Versatile Poses',
        'input-flexibility': 'Input Flexibility',
    };

    const tabContent: any = {
        'pixel-perfect':
            'Keeping perfect resemblance to the reference garment is our top priority and research focus',
        'bg-preservation':
            'Works in any setting; Not restricted to simple studio backgrounds',
        'versatile-poses':
            'Can handle all common fashion modeling poses and shot perspectives with ease',
        'input-flexibility':
            'Support for different garment photo types: worn by a person, flat lay, ghost mannequin, etc.',
    };

    const tabs = Object.keys(tabContent);

    const switchToNextTab = useCallback(() => {
        const currentIndex = tabs.indexOf(activeTab);
        const nextIndex = (currentIndex + 1) % tabs.length;
        setActiveTab(tabs[nextIndex]);
    }, [activeTab, tabs]);

    // useEffect(() => {
    //     if (!isHovered) {
    //         const interval = setInterval(switchToNextTab, 5000); // Switch every 5 seconds
    //         return () => clearInterval(interval);
    //     }
    // }, [isHovered, switchToNextTab]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            ref={containerRef}
            className="w-full z-10"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                <TabsList className="grid w-full grid-cols-4 gap-2">
                    {tabs.map((tab) => (
                        <TabsTrigger
                            key={`tab-name-${tab}`}
                            value={tab}
                            className={cn(
                                ' flex flex-col uppercase items-center',
                                headFont.className
                            )}
                        >
                            {tabsDisplay[tab]}
                        </TabsTrigger>
                    ))}
                </TabsList>
                <TabsContent value={'pixel-perfect'} className="h-[415px]">
                    <AnimatedTabContent>
                        <p className="text-lg">{tabContent['pixel-perfect']}</p>
                        <div className="flex gap-6 items-center justify-center">
                            <Image
                                width={208}
                                height={320}
                                priority
                                src={
                                    'https://utfs.io/f/wXFHUNfTHmLjockOw3IyECBmSQ8VfsaeMOpI9kRPYZ4UK3JA'
                                }
                                alt={'Model image'}
                                className="object-cover rounded-md"
                            />

                            <Image
                                width={208}
                                height={320}
                                priority
                                src={
                                    'https://utfs.io/f/wXFHUNfTHmLjsOtRbsJUW7xrMIuPClbYRiJysSHqN8ztgoBL'
                                }
                                alt={'Garment image'}
                                className="object-cover rounded-md"
                            />

                            <Lens hovering={hovering} setHovering={setHovering}>
                                <Image
                                    width={208}
                                    priority
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjZqVy7M2IJL7G8w6BNyzSbaARsF3xHjhWgZpm'
                                    }
                                    alt={'Try-on result'}
                                    className="object-cover rounded-md"
                                />
                            </Lens>
                        </div>
                    </AnimatedTabContent>
                </TabsContent>
                <TabsContent value={'bg-preservation'} className="h-[415px]">
                    <AnimatedTabContent>
                        <p className="text-lg">{tabContent['bg-preservation']}</p>
                        <div className="flex gap-6 items-center justify-center">
                            <Image
                                width={208}
                                height={320}
                                src={
                                    'https://utfs.io/f/wXFHUNfTHmLj9M8ecuWT5IXsA4Lhru0e7dJiKFwpQ6Glm28S'
                                }
                                alt={'Original model image'}
                                className="object-cover rounded-md"
                            />
                            <Image
                                width={208}
                                height={320}
                                src={
                                    'https://utfs.io/f/wXFHUNfTHmLje9k1oribhud8jN4HXGm5KfPwVvgAZq1lFRcx'
                                }
                                alt={'Try-on result'}
                                className="object-cover rounded-md"
                            />
                        </div>
                    </AnimatedTabContent>
                </TabsContent>
                <TabsContent value={'versatile-poses'} className="h-[415px]">
                    <AnimatedTabContent>
                        <p className="text-lg">{tabContent['versatile-poses']}</p>
                        <div className="flex gap-10">
                            <div className="flex gap-4 items-center justify-center">
                                <Image
                                    width={208}
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjxrKOZw10N8h1XUbaCizIZsxcVSOg5DQfvwPe'
                                    }
                                    alt={'Model image'}
                                    className="object-cover rounded-md"
                                />

                                <Image
                                    width={208}
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjZAAwg5Q2IJL7G8w6BNyzSbaARsF3xHjhWgZp'
                                    }
                                    alt={'Try-on result'}
                                    className="object-cover rounded-md"
                                />
                            </div>
                            <div className="flex gap-4 items-center justify-center">
                                <Image
                                    width={208}
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjonDwS3RIyECBmSQ8VfsaeMOpI9kRPYZ4UK3J'
                                    }
                                    alt={'Model image'}
                                    className="object-cover rounded-md"
                                />

                                <Image
                                    width={208}
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjJh68akeT2O60nz3rVSheCi5EuAb9WvNxBtQ8'
                                    }
                                    alt={'Try-on result'}
                                    className="object-cover rounded-md"
                                />
                            </div>
                        </div>
                    </AnimatedTabContent>
                </TabsContent>
                <TabsContent value={'input-flexibility'} className="h-[415px]">
                    <AnimatedTabContent>
                        <p className="text-lg">{tabContent['input-flexibility']}</p>
                        <div className="flex items-center gap-10">
                            <div className="flex gap-4">
                                <Image
                                    width={208}
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjKhj1ImxBC3LH4jlTciD02vZrnJXRagAuBGo1'
                                    }
                                    alt={'Model image'}
                                    className="object-cover rounded-md"
                                />

                                <Image
                                    width={208}
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjX01su1RFOWvY1TUkZJwbFom3xRHdD0qaptuG'
                                    }
                                    alt={'Try-on result'}
                                    className="object-cover rounded-md"
                                />
                            </div>

                            <div className="flex gap-4">
                                <Image
                                    width={208}
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjTpeARrjiI67wPAK3LUJX2un9yHmt1ElVoeSN'
                                    }
                                    alt={'Model image'}
                                    className="object-cover rounded-md"
                                />

                                <Image
                                    width={208}
                                    height={320}
                                    src={
                                        'https://utfs.io/f/wXFHUNfTHmLjo9KxXoIyECBmSQ8VfsaeMOpI9kRPYZ4UK3JA'
                                    }
                                    alt={'Try-on result'}
                                    className="object-cover rounded-md"
                                />
                            </div>
                        </div>
                    </AnimatedTabContent>
                </TabsContent>
            </Tabs>
        </div>
    );
}

const AnimatedTabContent = ({ children }: any) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="border border-primary p-6 rounded-lg mt-4 flex flex-col gap-6 items-center"
            transition={{
                type: 'spring',
                stiffness: 300,
                damping: 30,
                opacity: { duration: 0.2 },
            }}
        >
            {children}
        </motion.div>
    );
};
